<template>
    <div>
        <div class="mt-4">
            <h3 class="text-center">Referencias pendientes a entregar</h3>
            <p>
                En esta sección encontras el detalle de los productos con
                referencia que estan pendientes por ser entregados, es decir los
                productos de las ordenes que estan en curso.
            </p>
        </div>

        <div class="d-flex flex-wrap">
            <div
                class="d-flex flex-column justify-content-center align-items-center col-12 my-4"
            >
                <delivery-status-selector
                    v-model="deliveryStatus"
                    class="col-10 col-lg-6"
                    enabled
                />
                <div class="d-flex justify-content-center mt-2">
                    <vs-button relief @click="reloadItems">
                        Consultar 🔎
                    </vs-button>
                </div>
            </div>
            <div v-show="showTable" class="col-12 mt-4">
                <b-table
                    :fields="$data.$fields"
                    :items="itemsCurrent"
                    :dark="activeDark"
                    :per-page="itemsCurrent.length"
                    responsive
                    outlined
                    no-border-collapse
                    hover
                    class="col-12"
                >
                    <template #cell(id)="data">
                        <div class="d-flex align-items-center">
                            <router-link
                                :to="`/app/products/detail/${data.item.productId}?reference=${data.item.id}&active=references`"
                                class="text-center font-weight-normal"
                            >
                                <span>
                                    {{
                                        data.item.barcode
                                            ? `Cód. ${data.item.barcode}`
                                            : `ID ${data.item.id}`
                                    }}
                                </span>
                            </router-link>
                        </div>
                    </template>
                    <template #cell(productId)="data">
                        <router-link
                            :to="`/app/products/detail/${data.item.productId}`"
                            class="text-left font-weight-normal"
                        >
                            <span>
                                {{
                                    data.item.sku
                                        ? `SKU ${data.item.sku}`
                                        : `ID ${data.item.productId}`
                                }}
                            </span>
                        </router-link>
                    </template>

                    <template #cell(name)="data">
                        <small class="text-center">
                            {{ data.item.name }}
                        </small>
                    </template>
                    <template #cell(sku)="data">
                        <small class="text-center">
                            {{ data.item.sku }}
                        </small>
                    </template>
                    <template #cell(size)="data">
                        <small class="text-center text-capitalize">
                            {{ data.item.size }}
                        </small>
                    </template>
                    <template #cell(color)="data">
                        <small class="text-center text-capitalize">
                            {{ data.item.color }}
                        </small>
                    </template>
                    <template #cell(priceOffer)="data">
                        <small class="text-center">
                            {{ data.item.priceOffer | money }}
                        </small>
                    </template>
                    <template #cell(ordersLen)="data">
                        <small class="text-center text-primary">
                            {{ data.item.ordersLen }}
                        </small>
                    </template>
                    <template #cell(quantity)="data">
                        <small class="text-center text-primary">
                            {{ data.item.quantity }}
                        </small>
                    </template>
                </b-table>
                <div v-if="itemsCurrent.length" class="w-100">
                    <vs-button icon @click="downloadExcelComponent">
                        ⏬ Descargar excel
                    </vs-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import { deliveryStatusesMessageClass } from "@/common/fields/delivery-status-codes";
import { downloadExcel } from "@/common/lib/excel";

import DeliveryStatusSelector from "@/components/orders/DeliveryStatusSelector.vue";

export default {
    name: "ReferencesOrder",
    components: {
        DeliveryStatusSelector
    },
    layout: "app",
    meta: {
        roles: ["administrator"]
    },
    data: () => ({
        $deliveryStatusesMessageClass: deliveryStatusesMessageClass,
        loaderInstance: null,
        itemsCurrent: [],
        $fields: [
            { key: "id", label: "Ref.", sortable: false },
            { key: "productId", label: "Prod.", sortable: false },
            {
                key: "name",
                label: "Nombre del producto",
                sortable: true
            },
            {
                key: "sku",
                label: "SKU",
                sortable: false
            },
            {
                key: "size",
                label: "Talla",
                sortable: true
            },
            {
                key: "color",
                label: "Color",
                sortable: false
            },
            {
                key: "priceOffer",
                label: "Precio actual",
                sortable: true
            },
            {
                key: "quantity",
                label: "Cantidad",
                sortable: true
            },
            {
                key: "ordersLen",
                label: "Presente en",
                sortable: true
            }
        ],
        showTable: false,
        isLoading: false,
        deliveryStatus: ""
    }),
    computed: {
        ...mapState("control", ["activeDark"]),
        ...mapGetters("control", ["backgroundColor"])
    },
    watch: {
        deliveryStatus(value) {
            this.listItems(value);
        }
    },
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if (!(await verifyPageScope("sale:ListByProduct", "/app/orders")))
            return;
        this.listItems();
    },
    methods: {
        ...mapActions("orders", ["listNumberOrdersByReferences"]),
        async listItems() {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "radius" });
            try {
                const references = await this.listNumberOrdersByReferences(
                    this.deliveryStatus
                );
                this.itemsCurrent = references;
                this.showTable = true;
            } catch (error) {
                this.loaderInstance.close();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
            }
        },
        reloadItems() {
            this.listItems();
        },
        downloadExcelComponent() {
            const filename = `orders_by_references_${this.deliveryStatus}`;
            downloadExcel({
                rows: this.itemsCurrent.map((item) => ({
                    ...item,
                    orders: JSON.stringify(item.orders)
                })),
                filename
            });
        }
    }
};
</script>
